import React, { useState, useRef, useEffect } from "react";
import '../../../../../styles/src/menus/search/index.scss'
import { getIconByName } from "../../../../utils/iconByName";
import * as PhosphorIcons from "@phosphor-icons/react";
export interface ISearchResult {
  title: string;
  description: string;
  icon: PhosphorIcons.Icon;
  command?: string;
  url?: string
}
export const SearchBody: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState(""); // Stato per il termine di ricerca
  const [results, setResults] = useState<Array<ISearchResult>>([]); // Stato per i risultati
  const [isLoading, setIsLoading] = useState(false); // Stato per il caricamento
  const [error, setError] = useState<string | null>(null); // Stato per gli errori
  const searchTimeout = useRef<NodeJS.Timeout | null>(null); // Ref per il timeout
  const lastTerm = useRef<string | null>(null); // Ref per l'ultimo termine di ricerca

  useEffect(() => {
    const performSearch = async () => {
      if (!searchTerm.trim()) {
        setResults([]);
        return;
      }

      const value = searchTerm.trim();
      if (value === lastTerm.current) {
        return;
      }

      lastTerm.current = value;

      setIsLoading(true);

      setError(null);

      try {
        const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

        await sleep(500); // Simula una chiamata API

        // const response = await fetch(`/api/search?q=${encodeURIComponent(searchTerm)}`);
        // if (!response.ok) {
        //   throw new Error("Errore durante la ricerca");
        // }
        // const data = await response.json();

        const randomNumberBetween = (min: number = 0, max: number = 100) => Math.floor(Math.random() * (max - min + 1) + min);
        const data: { results: any } = {
          results: [
            { title: `Risultato ${randomNumberBetween()}`, description: `Descrizione risultato ${randomNumberBetween()}`, icon: '' },
            { title: `Risultato ${randomNumberBetween()}`, description: `Descrizione risultato ${randomNumberBetween()}`, icon: '' },
            { title: `Risultato ${randomNumberBetween()}`, description: `Descrizione risultato ${randomNumberBetween()}`, icon: '' },
            { title: `Risultato ${randomNumberBetween()}`, description: `Descrizione risultato ${randomNumberBetween()}`, icon: '' },
            { title: `Risultato ${randomNumberBetween()}`, description: `Descrizione risultato ${randomNumberBetween()}`, icon: '' },
          ]
        }
        const setUpIconData: Array<ISearchResult> = data.results.map((r: any) => {
          r.icon = getIconByName(r.icon);
          return r ;
        });

        setResults(setUpIconData || []);
      } catch (err) {
        setError((err as Error).message || "Errore sconosciuto");
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (searchTerm)
      searchTimeout.current = setTimeout(performSearch, 1000);

    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current); // Pulisce il timeout precedente se il valore cambia
      }
    };
  }, [searchTerm, setIsLoading, setError, setResults, lastTerm]);
  const onInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    let v = e.currentTarget.value;
    setSearchTerm(v);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let v = e.target.value
    setSearchTerm(v);
  };
  return (
    <div className="athm-search-body">
      <div className="athx-search-input-container">
        <span className="icon-glass">
          {isLoading ? <PhosphorIcons.Spinner size={20} className="spinner" /> : <PhosphorIcons.MagnifyingGlass size={20} />}
        </span>
        <form action="#" onSubmit={(e) => e.preventDefault()}>
          <input type="search" name="global_search" autoComplete="off" value={searchTerm} onInput={onInput} spellCheck={false} onChange={onChange} />
        </form>
      </div>
      <div className="athx-search-results-container">
        {results.map((r, index) => (
          <div key={index} className="athx-search-result">
            <div className="athx-sr-b">
              <div className="athx-sr-i">
                {React.createElement(r.icon, { size: 32 })}
              </div>
              <div className="athx-sr-n">
                <span className="title">{r.title}</span>
                <span className="description">{r.description}</span>
              </div>

            </div>
            <a className="athx-sr-a">
              <PhosphorIcons.CaretRight size={32} />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}