import React, { useEffect, useRef } from "react";
import { useMenu } from "..";
import "../../../styles/src/menus/calendar/index.scss";
import { MenuHeader } from "../src/menu-header";
import { MenuBody } from "../src/menu-body";
import { CalendarBody } from "./src/body";
import { Spinner } from "@phosphor-icons/react";
export function CalendarMenu() {
  const { isOpen: { calendar: isOpenCalendarMenu }, isFullScreen: { calendar: isFullScreenCalendarMenu }, toggleFullScreen, setMenuRef } = useMenu();
  const menuRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => { setMenuRef('calendar', menuRef.current); return () => { setMenuRef('calendar', null); }; }, [setMenuRef]);
  const handleFullscreenClick = (event: React.MouseEvent<HTMLButtonElement>) => { event.stopPropagation(); toggleFullScreen('calendar'); };
  return (
    <div ref={menuRef} className={`athenorix-menu athenorix-menu-middle calendar-menu ${isOpenCalendarMenu ? 'open' : ''} ${isFullScreenCalendarMenu ? 'fullscreen' : ''}`}>
      <MenuHeader title="Calendario" haveFullscreen={true} handleClick={handleFullscreenClick} isInFullScreen={isFullScreenCalendarMenu} isOpen={isOpenCalendarMenu}></MenuHeader>
      <MenuBody>
        {!isFullScreenCalendarMenu ?
          <CalendarBody />
          : <div style={{ color: 'white',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center' }}><Spinner className={`spinner`} size={32}/> In fase di realizzazione</div>}
      </MenuBody>
    </div>
  );
}