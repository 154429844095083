import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Theme = 'default' | 'attractive' | 'energy' | 'vibe' | 'classic';

const initialState: Theme = localStorage.getItem('athx-theme') as Theme || 'default';

const themeSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<Theme>) {
      const theme = action.payload;
      document.documentElement.setAttribute('athx-theme', theme);
      localStorage.setItem('athx-theme', theme);
      return action.payload as typeof state;
    }
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
