import React, { MouseEventHandler } from "react";
import { MenuHeaderFullscreener } from "../menu-fullscreener";
import { isMobile } from 'react-device-detect';
import { useMenu } from "../..";

import { useGesture } from '@use-gesture/react';
import { CaretDown } from "@phosphor-icons/react";
export function MenuHeader({ title, haveFullscreen, handleClick, isInFullScreen, handleClose, isOpen }: { title: string, haveFullscreen: boolean, handleClick: MouseEventHandler<HTMLButtonElement>, handleClose?: MouseEventHandler<HTMLButtonElement>, isInFullScreen: boolean, isOpen: boolean }) {
  const { closeMenu } = useMenu();
  const bind = useGesture({
    onDrag: ({ movement: [mx, my], direction: [dx, dy], memo, event }) => {
      event.stopPropagation();
      if (dy > 0 && my > 100) {
        closeMenu('all');
      }
      return memo;
    },
  });
  const handleClickClose = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    // event.preventDefault();
    closeMenu('all');
  }
  return (
    <div className="athm-header" >
      <button className="athm-touch-closer" {...bind()} onClick={handleClickClose}><CaretDown size={20} /></button>
      {haveFullscreen && !isMobile &&
        <MenuHeaderFullscreener isFullScreen={isInFullScreen} handleClick={handleClick}></MenuHeaderFullscreener>
      }
      <span className="athm-title">{title}</span>
    </div>
  )
}