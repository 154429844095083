import { configureStore } from '@reduxjs/toolkit';
import sideMenuReducer from './window/side/index';
import themeSlice from './root/index';
import alertSlice from './alert/index';
import footerSlice from './footer/index';
import appSettingsSlice from './appSettings/index';
export const store = configureStore({
  reducer: {
    sideMenu: sideMenuReducer,
    theme: themeSlice,
    alerts: alertSlice,
    footer: footerSlice,
    appSettings: appSettingsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
