import React, { useState, useRef, useEffect } from 'react';
import type { Icon } from "@phosphor-icons/react";

interface ATHXDropDownOption {
  icon?: Icon;
  image?: string;
  label: string;
  value: string;
  theme?: string;
  css?: Record<string, string>;
  className?: string;
}
interface ATHXDropSelectProps {
  dropdownName: string;
  options: Array<ATHXDropDownOption>;
  onSelect: (value: string) => void;
  buttonClassName?: string;
  className?: string;
  css?: Record<string, string>;
  drClassName?: string;
  preselectedValue?: string;
  id?: string;
  name?: string;
}

export const DropSelect: React.FC<ATHXDropSelectProps> = ({ options, dropdownName, onSelect, buttonClassName, className, drClassName, css, preselectedValue, id, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ATHXDropDownOption | null>(
    preselectedValue ? options.find(option => option.value === preselectedValue) || null : null
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = (option: ATHXDropDownOption) => {
    onSelect(option.value);
    setSelectedOption(option);
    selectRef.current!.value = option.value;
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setIsOpen, dropdownRef]);

  useEffect(() => {
    if (preselectedValue) {
      const preselectedOption = options.find(option => option.value === preselectedValue);
      if (preselectedOption) {
        setSelectedOption(preselectedOption);
      }
    }
  }, [preselectedValue, options]);

  return (
    <div className={`athx-dropdown ${drClassName ? drClassName : ''}`} ref={dropdownRef}>
      <button
        onClick={handleToggle}
        className={`athx-dropdown-toggle ${buttonClassName ? buttonClassName : ''}`}
        data-value={selectedOption ? selectedOption.value : ''}
        type='button'
      >
        {selectedOption ? (
          <>
            {selectedOption.icon ? React.createElement(selectedOption.icon, { size: 18 }) : (selectedOption.image ? <img src={selectedOption.image} alt={selectedOption.label} /> : null)}
            {selectedOption.label}
          </>
        ) : (
          dropdownName || "Seleziona un'opzione"
        )}
      </button>
      <select name={name} id={id} ref={selectRef} defaultValue={selectedOption ? selectedOption.value : ''}>
        {options.map((option, index) =>
          <option
            key={index}
            value={option.value}
          >{option.label}</option>
        )}
      </select>
      {isOpen && (
        <ul className={`athx-dropdown-menu select ${isOpen ? 'open' : ''} ${className || ''}`}>
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleSelect(option)}
              className={`athx-dropdown-item ${option.theme ? option.theme : ''} ${option.className ? option.className : ''}`}
              style={option.css ? option.css : {}}
            >
              {option.icon ? React.createElement(option.icon, { size: 18 }) : option.image ? <img src={option.image} alt={option.label} /> : null}
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
