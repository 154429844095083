import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import '../../styles/src/menus/index.scss';

// Definiamo i tipi per i nomi dei menu
export type MenuName = 'app' | 'search' | 'calendar' | 'notification' | 'email' | 'messages' | 'mini' | 'tasks';

// Creiamo un contesto per il menu
const MenuContext = createContext({
  isOpen: {} as Record<MenuName, boolean>,
  isFullScreen: {} as Record<MenuName, boolean>,
  toggleMenu: (menu: MenuName) => { },
  closeMenu: (whichOpen?: MenuName | 'all') => { },
  toggleFullScreen: (menu: MenuName) => { },
  setMenuRef: (menu: MenuName, ref: HTMLDivElement | null) => { },
});

export function MenuProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState<Record<MenuName, boolean>>({
    app: false,
    search: false,
    calendar: false,
    notification: false,
    email: false,
    messages: false,
    mini: false,
    tasks: false,
  });
  const [isFullScreen, setIsFullScreen] = useState<Record<MenuName, boolean>>({
    app: false,
    search: false,
    calendar: false,
    notification: false,
    email: false,
    messages: false,
    mini: false,
    tasks: false,
  });
  const menuRefs = useRef<Record<MenuName, HTMLDivElement | null>>({
    app: null,
    search: null,
    calendar: null,
    notification: null,
    email: null,
    messages: null,
    mini: null,
    tasks: null,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const $target = event.target as HTMLElement;
      const $desktop = $target.closest('#desktop');
      if($desktop){
        closeMenu('all');
      }
    };

    const handlePopState = () => {
      if (Object.values(isOpen).some((menu) => menu)) {
        closeMenu('all');
      }
    };

    document.addEventListener("click", handleClickOutside);
    window.addEventListener("popstate", handlePopState);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isOpen]);

  const closeMenu = (whichOpen: MenuName | 'all' = 'all') => {
    if (whichOpen === 'all') {
      setIsOpen({
        app: false,
        search: false,
        calendar: false,
        notification: false,
        email: false,
        messages: false,
        mini: false,
        tasks: false,
      });
    } else {
      setIsOpen((prev) => ({ ...prev, [whichOpen]: false }));
    }
  };

  const toggleFullScreen = (menu: MenuName) => {
    setIsFullScreen((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  }

  const toggleMenu = (menu: MenuName) => {
    setIsOpen((prev) => {
      // Se il menu selezionato è già aperto, chiudi tutto
      if (prev[menu]) {
        return {
          app: false,
          search: false,
          calendar: false,
          notification: false,
          email: false,
          messages: false,
          mini: false,
          tasks: false,
        };
      }

      // Altrimenti chiudi tutti e apri solo quello selezionato
      return {
        app: false,
        search: false,
        calendar: false,
        notification: false,
        email: false,
        messages: false,
        mini: false,
        tasks: false,
        [menu]: true,
      };
    });
  };


  return (
    <MenuContext.Provider value={{
      isOpen,
      isFullScreen,
      toggleFullScreen,
      toggleMenu,
      closeMenu,
      setMenuRef: (menu: MenuName, ref: HTMLDivElement | null) => {
        menuRefs.current[menu] = ref;
      }
    }}>
      {children}
    </MenuContext.Provider>
  );
}

// Custom hook per accedere al contesto
export function useMenu() {
  return useContext(MenuContext);
}
