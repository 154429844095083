import React, { useState, useRef, useEffect, ReactNode } from 'react';
import '../../styles/src/popover/index.scss';
import { useSelector } from 'react-redux';
import {
  selectUserLevel,
  ApplicationSettings,
  USER_LEVEL_ALL
} from '../../store/appSettings';


type ShowOn = 'hover' | 'focus' | 'click';
type ShowAt =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

interface AthxPopoverProps {
  children: ReactNode[];
  showOn: ShowOn;
  showAt: ShowAt;
  showFor?: ApplicationSettings['userLevel'];
  className?: string;
}

export const AthxPopover: React.FC<AthxPopoverProps> = ({ children, showOn, showAt, showFor, className }) => {
  const userLevel = useSelector(selectUserLevel);

  const show = typeof showFor === 'undefined' || (typeof showFor === 'number' && showFor === USER_LEVEL_ALL) ? true : showFor as ApplicationSettings['userLevel'] >= userLevel;



  const [visible, setVisible] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const handleMouseEnter = () => {
    if (!show) return;
    if (showOn === 'hover') setVisible(true);
  };

  const handleMouseLeave = () => {
    if (!show) return;
    if (showOn === 'hover') setVisible(false);
  };

  const handleFocus = () => {
    if (!show) return;
    if (showOn === 'focus') setVisible(true);
  };

  const handleBlur = () => {
    if (!show) return;
    if (showOn === 'focus') setVisible(false);
  };

  const handleClick = () => {
    if (!show) return;
    if (showOn === 'click') setVisible((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node) &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setVisible(false);
      }
    };

    if (showOn === 'click' && show) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (showOn === 'click' && show) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [showOn, show]);

  const getPopoverPosition = () => {
    if (!show)
      return { display: 'none' };
    switch (showAt) {
      case 'top-left':
        return { bottom: 'calc(100% + .25rem)', left: '0' };
      case 'top-center':
        return { bottom: 'calc(100% + .25rem)', left: '50%', transform: 'translateX(-50%)' };
      case 'top-right':
        return { bottom: 'calc(100% + .25rem)', right: '0' };
      case 'bottom-left':
        return { top: 'calc(100% + .25rem)', left: '0' };
      case 'bottom-center':
        return { top: 'calc(100% + .25rem)', left: '50%', transform: 'translateX(-50%)' };
      case 'bottom-right':
        return { top: 'calc(100% + .25rem)', right: '0' };
      default:
        return { bottom: 'calc(100% + .25rem)', left: '50%', transform: 'translateX(-50%)' };
    }
  };

  return (
    !show ? <>{children[0]}</> :
      <div
        ref={triggerRef}
        className={`athx-popover-trigger ${className ? className : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        tabIndex={0}
      >
        {children[0]}
        {visible && (
          <div
            ref={popoverRef}
            className="athx-popover-content"
            style={{ ...getPopoverPosition(), position: 'absolute' }}
          >
            {children[1]}
          </div>
        )}
      </div>
  );

};
