import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface CalendarEvent {
  day: number;
  month: number;
  year: number;
}
export interface FooterState {
  hasProcesses: boolean;
  hasMessages: boolean;
  hasTasks: boolean;
  hasEvents: boolean;
  hasEmails: boolean;
  hasNotifications: boolean;
}
const initialState: FooterState = {
  hasProcesses: false,
  hasMessages: false,
  hasTasks: false,
  hasEvents: false,
  hasEmails: false,
  hasNotifications: false,
}
const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    setProcesses: (state, action: PayloadAction<boolean>) => {
      state.hasProcesses = action.payload;
    },
    setMessages: (state, action: PayloadAction<boolean>) => {
      state.hasMessages = action.payload;
    },
    setTasks: (state, action: PayloadAction<boolean>) => {
      state.hasTasks = action.payload;
    },
    setEvents: (state, action: PayloadAction<boolean>) => {
      state.hasEvents = action.payload;
    },
    setEmails: (state, action: PayloadAction<boolean>) => {
      state.hasEmails = action.payload;
    },
    setNotifications: (state, action: PayloadAction<boolean>) => {
      state.hasNotifications = action.payload;
    }
  }
});
export const { setProcesses, setMessages, setTasks, setEvents, setEmails, setNotifications } = footerSlice.actions;
export const selectHasProcesses = (state: { footer: FooterState }) => state.footer.hasProcesses;
export const selectHasMessages = (state: { footer: FooterState }) => state.footer.hasMessages;
export const selectHasTasks = (state: { footer: FooterState }) => state.footer.hasTasks;
export const selectHasEvents = (state: { footer: FooterState }) => state.footer.hasEvents;
export const selectHasEmails = (state: { footer: FooterState }) => state.footer.hasEmails;
export const selectHasNotifications = (state: { footer: FooterState }) => state.footer.hasNotifications;




export default footerSlice.reducer;
