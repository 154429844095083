import React, { useState, useRef, useEffect } from 'react';
import '../../styles/src/common/dropdown.scss';
import type { Icon } from "@phosphor-icons/react";

interface ATHXDropDownOption {
  icon?: Icon;
  image?: string;
  label: string;
  value: string;
  theme?: string;
  css?: Record<string, string>;
  className?: string;
}
interface ATHXDropdownProps {
  dropdownName: string;
  options: Array<ATHXDropDownOption>;
  onSelect: (value: string) => void;
  buttonClassName?: string;
  className?: string;
  css?: Record<string, string>;
  drClassName?: string;
}

export const Dropdown: React.FC<ATHXDropdownProps> = ({ options, dropdownName, onSelect, buttonClassName, className, drClassName,css }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = (option: string) => {
    onSelect(option);
    setIsOpen(false);
  };


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setIsOpen, dropdownRef]);

  return (
    <div className={`athx-dropdown ${drClassName && drClassName || ''}`} ref={dropdownRef}>
      <button onClick={handleToggle} className={`athx-dropdown-toggle toggle ${buttonClassName ? buttonClassName : ''}`}>
        {dropdownName || "Seleziona un'opzione"}
      </button>
      {isOpen && (
        <ul className={`athx-dropdown-menu ${isOpen ? 'open' : ''} ${className || ''}`}>
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleSelect(option.value)}
              className={`athx-dropdown-item ${option.theme ? option.theme : ''} ${option.className ? option.className : ''}`}
              style={option.css ? option.css : {}}
            >

              {option.icon ? React.createElement(option.icon, { size: 18 }) : option.image ? <img src={option.image} alt={option.label} /> : null}
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

