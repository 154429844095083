import { createSlice, PayloadAction } from '@reduxjs/toolkit';
type USER_LEVEL_NEW = 0;
type USER_LEVEL_INTERMEDIATE = 1;
type USER_LEVEL_ADVANCED = 2;
type USER_LEVEL_ALL = -1;

export const USER_LEVEL_ALL: USER_LEVEL_ALL = -1;
export const USER_LEVEL_NEW: USER_LEVEL_NEW = 0;
export const USER_LEVEL_INTERMEDIATE: USER_LEVEL_INTERMEDIATE = 1;
export const USER_LEVEL_ADVANCED: USER_LEVEL_ADVANCED = 2;

export interface ApplicationSettings {
  userLevel: USER_LEVEL_NEW | USER_LEVEL_INTERMEDIATE | USER_LEVEL_ADVANCED | USER_LEVEL_ALL;
}
const initialState: ApplicationSettings = {
  userLevel: USER_LEVEL_NEW
}
const appSettingsSlice = createSlice({
  name: 'appsettings',
  initialState,
  reducers: {
    setUserLevel: (state, action: PayloadAction<USER_LEVEL_NEW | USER_LEVEL_INTERMEDIATE | USER_LEVEL_ADVANCED>) => {
      state.userLevel = action.payload;
    }
  }
});
export const { setUserLevel } = appSettingsSlice.actions;
export const selectUserLevel = (state: { appSettings: ApplicationSettings }) => state.appSettings.userLevel;

export default appSettingsSlice.reducer;
