import React from 'react';
import '../../styles/src/alert/index.scss';
import { useSelector } from 'react-redux';
import { addAlert, } from '../../store/alert';
import { RootState, store } from '../../store';
import { Alert } from './src/alert';

export type AlertData = {
  id: number;
  title: string;
  message: string;
  theme: 'primary' | 'accent' | 'secondary' | 'info' | 'error' | 'warning' | 'success' | 'interactive';
  timeout?: boolean;
};

export const AlertContainer: React.FC = () => {
  const alerts = useSelector((state: RootState) => state.alerts.alerts);

  return (
    <div className="alert-overlay">
      <div className="alert-container">
        {alerts.map((alert) => (
          <Alert key={alert.id} id={alert.id} message={alert.message} title={alert.title} timeout={alert.timeout} theme={alert.theme} />
        ))}
      </div>
    </div>
  );
};




export const ShowAlertWindow = (title: string, message: string, theme: AlertData['theme'], timeout?: boolean) => {
  store.dispatch(addAlert({ id: Date.now(), title, message, theme, timeout }));
};
export const alertError = (message: string, timeout?: boolean) => ShowAlertWindow('Errore', message, 'error', timeout);
export const alertInfo = (message: string, timeout?: boolean) => ShowAlertWindow('Informazione', message, 'info', timeout);
export const alertSuccess = (message: string, timeout?: boolean) => ShowAlertWindow('Successo', message, 'success', timeout);
export const alertWarning = (message: string, timeout?: boolean) => ShowAlertWindow('Attenzione', message, 'warning', timeout);
export const alertInteractive = (message: string, timeout?: boolean) => ShowAlertWindow('Interazione', message, 'interactive', timeout);
export const alertPrimary = (message: string, timeout?: boolean) => ShowAlertWindow('Principale', message, 'primary', timeout);
export const alertSecondary = (message: string, timeout?: boolean) => ShowAlertWindow('Secondario', message, 'secondary', timeout);
export const alertAccent = (message: string, timeout?: boolean) => ShowAlertWindow('Accent', message, 'accent', timeout);
