import React from "react";
import { useMenu } from "../../menus";
import { CaretUp, Chats, ListChecks, CalendarDots, Bell, Mailbox } from "@phosphor-icons/react";
import { store, RootState } from '../../../store/index';
import { useSelector } from "react-redux";
import {
  selectHasProcesses,
  selectHasMessages,
  selectHasTasks,
  selectHasEvents,
  selectHasEmails,
  selectHasNotifications
} from "../../../store/footer";

export function RightMenu() {
  const {
    isOpen: {
      messages: isOpenMessagesMenu,
      calendar: isOpenCalendarMenu,
      email: isOpenEmailsMenu,
      mini: isOpenMiniMenu,
      notification: isOpenNotificationsMenu,
      tasks: isOpenTasksMenu
    }, toggleMenu
  } = useMenu();

  const hasProcesses = useSelector(selectHasProcesses);
  const hasMessages = useSelector(selectHasMessages);
  const hasTasks = useSelector(selectHasTasks);
  const hasEvents = useSelector(selectHasEvents);
  const hasEmails = useSelector(selectHasEmails);
  const hasNotifications = useSelector(selectHasNotifications);

  const handleMessagesMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('messages');
  };
  const handleCalendarMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('calendar');
  };
  const handleEmailsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('email');
  };
  const handleMiniMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('mini');
  };
  const handleNotificationMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('notification');
  };
  const handleTasksMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('tasks');
  };


  return (
    <div className="f-item right-menu">
      <button className={`f-menu-trigger f-mini-menu ${hasProcesses ? 'has-events' : ''} ${isOpenMiniMenu ? 'active' : ''}`} onClick={handleMiniMenuClick}>
        <CaretUp size={32} weight="bold" />
      </button>
      <button className={`f-menu-trigger f-messages-menu ${hasMessages ? 'has-events' : ''}  ${isOpenMessagesMenu ? 'active' : ''}`} onClick={handleMessagesMenuClick}>
        <Chats size={32} weight="bold" />
      </button>
      <button className={`f-menu-trigger f-tasks-menu ${hasTasks ? 'has-events' : ''}  ${isOpenTasksMenu ? 'active' : ''}`} onClick={handleTasksMenuClick}>
        <ListChecks size={32} weight="bold" />
      </button>
      <button className={`f-menu-trigger f-calendar-menu ${hasEvents ? 'has-events' : ''}  ${isOpenCalendarMenu ? 'active' : ''}`} onClick={handleCalendarMenuClick}>
        <CalendarDots size={32} weight="bold" />
      </button>
      <button className={`f-menu-trigger f-emails-menu ${hasEmails ? 'has-events' : ''}  ${isOpenEmailsMenu ? 'active' : ''}`} onClick={handleEmailsMenuClick}>
        <Mailbox size={32} weight="bold" />
      </button>
      <button className={`f-menu-trigger f-notification-menu ${hasNotifications ? 'has-events' : ''}  ${isOpenNotificationsMenu ? 'active' : ''}`} onClick={handleNotificationMenuClick}>
        <Bell size={32} weight="bold" />
      </button>
    </div>
  );
}