import '../../../../../styles/src/menus/messages/src/body/index.scss';
import { MessageMenuBodyItem } from '../item';

const fakeData = [
  {
    id: 'asdf',
    imgSrc: 'fake/fake1.jpg',
    name: 'Mario Rossi',
    message: 'Ciao, come stai?',
    time: '15:30',
    count: 1,
    lastMessageFromReceiver: false,
    read: false

  },
  {
    id: 'asdf2',
    imgSrc: 'fake/fake2.jpg',
    name: 'Luigi Bianchi',
    message: 'Si grazie, tu?',
    time: '15:30',
    count: 0,
    lastMessageFromReceiver: true,
    read: true
  },
  {
    id: 'asdf3',
    imgSrc: 'fake/fake3.jpg',
    name: 'Giuseppe Verdi',
    message: 'Come procede lo sviluppo?',
    time: '15:30',
    count: 0,
    lastMessageFromReceiver: false,
    read: true
  }
]
export function MessagesMinBody() {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    // event.preventDefault();
  }
  return (
    <ul className="athm-messages-body athm-min-menu">
      <MessageMenuBodyItem key={fakeData[0].id} imgSrc={fakeData[0].imgSrc} name={fakeData[0].name} message={fakeData[0].message} time={fakeData[0].time} count={fakeData[0].read ? 0 : fakeData[0].count} lastMessageFromReceiver={fakeData[0].lastMessageFromReceiver} read={fakeData[0].read && !fakeData[0].lastMessageFromReceiver} onClick={handleClick}></MessageMenuBodyItem>
      <MessageMenuBodyItem key={fakeData[1].id} imgSrc={fakeData[1].imgSrc} name={fakeData[1].name} message={fakeData[1].message} time={fakeData[1].time} count={fakeData[1].read ? 0 : fakeData[1].count} lastMessageFromReceiver={fakeData[1].lastMessageFromReceiver} read={fakeData[1].read && !fakeData[1].lastMessageFromReceiver} onClick={handleClick}></MessageMenuBodyItem>
      <MessageMenuBodyItem key={fakeData[2].id} imgSrc={fakeData[2].imgSrc} name={fakeData[2].name} message={fakeData[2].message} time={fakeData[2].time} count={fakeData[2].read ? 0 : fakeData[2].count} lastMessageFromReceiver={fakeData[2].lastMessageFromReceiver} read={fakeData[2].read && !fakeData[2].lastMessageFromReceiver} onClick={handleClick}></MessageMenuBodyItem>
    </ul>
  );
}