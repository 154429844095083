import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertData } from '../../components/alert';

export interface AlertState {
  alerts: AlertData[];
  queuedAlert: AlertData | null;
}

const initialState: AlertState = {
  alerts: [],
  queuedAlert: null,
};

const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<AlertData>) => {
      if (state.alerts.length === 0) {
        state.alerts.push(action.payload);
      } else {
        state.queuedAlert = action.payload;
      }
    },
    removeAlert: (state, action: PayloadAction<number>) => {
      state.alerts = state.alerts.filter(alert => alert.id !== action.payload);
      if (state.queuedAlert) {
        state.alerts.push(state.queuedAlert);
        state.queuedAlert = null;
      }
    }
  },
});

export const { addAlert, removeAlert } = alertSlice.actions;

export default alertSlice.reducer;
