
// Alert.tsx
import React, { useEffect } from 'react';
import '../../../styles/src/alert/index.scss';
import { Prohibit, Warning, Info, QuestionMark, CheckCircle, X } from '@phosphor-icons/react';
import { useDispatch } from 'react-redux';
import { removeAlert, } from '../../../store/alert';
import { AlertData } from '..';

export type AlertProps = {
  alert: AlertData;
};

export const Alert: React.FC<AlertData> = ({ title, message, id, timeout, theme }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (timeout) {
      setTimeout(() => {
        dispatch(removeAlert(id));
      }, 3000);
    }
    return () => clearInterval(timer);
  }, [id, timeout, dispatch]);

  const remove = () => {
    dispatch(removeAlert(id));
  };

  const icon = (() => {
    switch (theme) {
      case 'error': return <Prohibit size={96} className="text-error" />;
      case 'warning': return <Warning size={96} className="text-warning" />;
      case 'success': return <CheckCircle size={96} className="text-success" />;
      case 'info': return <Info size={96} className="text-info" />;
      case 'interactive': return <QuestionMark size={96} className="text-interactive" />;
      default: return <img src="../../../public/athenorix-logo.png" style={{ maxWidth: '6rem', maxHeight: '6rem' }} alt={'Athenorix Logo'} />;
    }
  })();

  return (
    <div
      id={id.toString()}
      className={`alert-window ${theme}`}
      style={{ position: 'relative', marginBottom: '1rem', zIndex: 9999 }}
    >
      <div className="athx-alert-window-header">
        <span></span>
        {icon}
        <button className='athx-alert-window-close' onClick={remove}><X size={24} /></button>
      </div>
      <div className="athx-alert-window-body">
        {title && title !== '' && <h3>{title}</h3>}
        {message.trim().startsWith('<') ? <div dangerouslySetInnerHTML={{ __html: message }} /> : <p>{message}</p>}
      </div>
      {timeout && (
        <div className="athx-alert-window-footer">
          <div className="progress-bar-container">
            <div className="progress-bar" />
          </div>
        </div>
      )}
    </div>
  );
};