import React, { useEffect, useState, useCallback } from 'react';
import { Warning, Spinner, X, PencilSimple } from '@phosphor-icons/react';

interface ICEvent {
  id: string;
  title: string;
  date: Date;
  description: string;
}

export const DayEvents: React.FC<{ date: Date }> = ({ date }) => {
  const [events, setEvents] = useState<ICEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);


  const fetchDayEvents = useCallback(() => {
    try {
      if (!loading && events.length > 0) {
        return;
      }

      /**
       * Create API call to fetch events
       */
      const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
      const fakeApi = async () => {
        await sleep(2000);
        const random = (min: number = 0, max: number = 10) => Math.floor(Math.random() * (max - min + 1)) + min;
        const data: { events: Array<ICEvent> } = {
          events: []
        }

        let hasEvents = [true, false, true, false, true, false][random(0, 5)];
        if (hasEvents)
          for (let i = 0; i < random(1, 15); i++) {
            date.setHours(random(0, 23), random(0, 59), random(0, 59), random(0, 999));
            data.events.push({
              id: `${random(1, 1000)}`,
              title: `Evento ${random(1, 15)}`,
              description: `Descrizione evento ${random(1, 15)}`,
              date: date
            });
          }
        return data;
      }
      setLoading(true);
      fakeApi().then(data => {
        if (JSON.stringify(events) !== JSON.stringify(data.events))
          setEvents(data.events);
      }).catch(e => {
        setError('Si è verificato un errore durante il caricamento dei dati');
        console.error(e);
      }).finally(() => {
        setLoading(false);
      });

    } catch (e) {
      setError('Si è verificato un errore durante il caricamento dei dati');
      console.error(e);
    }
  }, [date, events, loading]);

  useEffect(() => {
    fetchDayEvents();
  }, [fetchDayEvents]);

  // return (<h6><Warning size={24} /> Si è verificato un errore durante il caricamento degli eventi</h6>);


  return (
    <>
      {
        loading
          ? (<h6><Spinner className="spinner" size={24} /> Caricamento eventi </h6>)
          : error ? (<h6><Warning size={24} /> Si è verificato un errore durante il caricamento degli eventi</h6>) :
            (events.length === 0
              ? (<h6><X size={24} /> Nessun evento programmato per la data {date.toLocaleDateString("it-IT", { year: "numeric", month: "long", day: "numeric" })}</h6>)
              : <ul className='athx-c-e-list'>
                {events.map(event => (
                  <li key={event.id}>
                    <div className="head">
                      <span className="title"><span className="time">{event.date.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}</span>{event.title}</span>
                      <span className="description">{event.description}</span>
                    </div>
                    <div className="control">
                      <button data-event-id={event.id} className='edit-c-e-event'>
                        <PencilSimple size={24} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>)
      }
    </>
  );
};