// store/sideMenuSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SideMenuState {
  menus: {
    [key: string]: {
      opened: boolean;
      data: any;

    }; // Ogni chiave rappresenta un SideMenu, con true/false per opened
  };
}

const initialState: SideMenuState = {
  menus: {},
};

const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    addSideToState(state, action: PayloadAction<string>) {
      const id = action.payload;
      state.menus[id] = {
        opened: false,
        data: null,
      };
    },
    toggleSideMenu(state, action: PayloadAction<string>) {
      const id = action.payload;
      id in state.menus && (state.menus[id].opened = !state.menus[id].opened);
    },
    setSideMenuOpened(state, action: PayloadAction<{ id: string; opened: boolean }>) {
      const { id, opened } = action.payload;
      if (id in state.menus)
        state.menus[id].opened = opened;
    },
    removeMenuFromState(state, action: PayloadAction<string>) {
      const id = action.payload;
      delete state.menus[id];
    }
  },
});

export const { toggleSideMenu, setSideMenuOpened, removeMenuFromState, addSideToState } = sideMenuSlice.actions;
export default sideMenuSlice.reducer;
